<template>
  <div class="dispute-amounts d-flex flex-column">
    <template v-if="amount === 0">
      <span class="amount paid">{{amount}}</span>
    </template>
    <template v-else>
      <span class="amount">{{balance}}</span>
      <span
        class="original-amount"
        v-if="amount !== balance"
      >{{amount}}</span>
    </template>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  computed: {
    amount () {
      if (this.value == null) {
        return null
      }
      const valueType = typeof this.value.amt
      if (valueType === 'object') {
        return this.formatCurrency(this.value.amt.amt, this.value.amt.cur)
      }
      return this.formatCurrency(this.value.amt)
    },
    balance () {
      if (this.value == null) {
        return null
      }
      const valueType = typeof this.value.bal
      if (valueType === 'object') {
        return this.formatCurrency(this.value.bal.amt, this.value.bal.cur)
      }
      return this.formatCurrency(this.value.bal)
    }
  },
  props: {
    value: {
      type: Object
    }
  }
}
</script>

<style lang="stylus" scoped>
.dispute-amounts
  .paid
    text-decoration line-through

  .original-amount
    font-size 0.75em
</style>
